<template>
  <div class="kt-grid kt-wizard-v1 kt-wizard-v1--white">
    <div class="kt-grid__item mb-5">
      <div class="kt-wizard-v1__nav">
        <div class="kt-wizard-v1__nav-items">
          <div
            v-for="item in steps"
            :key="item.id"
            class="kt-wizard-v1__nav-item"
            data-ktwizard-type="step"
            :data-ktwizard-state="currStep(item.id)"
          >
            <div class="kt-wizard-v1__nav-body">
              <div class="kt-wizard-v1__nav-icon">
                <i :class="item.icon"></i>
              </div>
              <div class="kt-wizard-v1__nav-label">{{ item.text }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'stepBar',
  props: {
    currIndex: Number
  },
  data() {
    return {
      steps: [
        { id: 1, text: 'Prospecting', icon: 'flaticon-computer' },
        { id: 2, text: 'Qualification', icon: 'flaticon-customer' },
        { id: 3, text: 'Value Proposition', icon: 'flaticon-statistics' },
        { id: 4, text: 'Contact Decision Maker', icon: 'flaticon-profile-1' },
        { id: 5, text: 'First Official Appointment', icon: 'flaticon-globe' },
        { id: 6, text: 'Negotiation / Review', icon: 'flaticon-time' },
        { id: 7, text: 'Contract Out', icon: 'flaticon-folder-1' },
        { id: 8, text: 'Wait for Signature', icon: 'flaticon-list' }
      ]
    };
  },
  components: {},
  methods: {
    currStep(id) {
      return this.currIndex >= id ? 'current' : '';
    }
  },
  computed: {},
  created() {}
};
</script>
